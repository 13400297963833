<template>
  <div>
    <b-tabs>
      <b-tab v-if="acesso.DashboardsCadastro">
        <template #title>
          <feather-icon icon="UserPlusIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Cadastro</span>
        </template>
        <DashboardCadastro />
      </b-tab>

      <b-tab v-if="acesso.DashboardsProposta">
        <template #title>
          <feather-icon icon="ArchiveIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Proposta</span>
        </template>
        <DashboardSimulacaoProposta />
      </b-tab>

      <b-tab v-if="acesso.DashboardsFinanceiro">
        <template #title>
          <feather-icon icon="SlidersIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Financeiro</span>
        </template>
        <DashBoardFinanceiro />
      </b-tab>
    </b-tabs>
    <b-card class="text-center" v-if="todosFalsos">
      <b-card-body>
        <b-icon icon="exclamation-triangle" variant="danger" font-scale="2"></b-icon>
        <b-card-title class="text-danger mt-2">Acesso Negado!</b-card-title>
        <b-card-text class="mb-1">Parece que seu usuário não tem permissão para acessar abas deste módulo.</b-card-text>
        <b-card-text class="mb-1"
          >Para obter acesso, por favor, solicite assistência ou permissões adicionais ao administrador do sistema.</b-card-text
        >
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import DashboardCadastro from './DashboardCadastro.vue'
  import DashboardSimulacaoProposta from './DashboardSimulacaoProposta.vue'
  import DashBoardFinanceiro from './DashBoardFinanceiro.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      DashboardCadastro,
      DashBoardFinanceiro,
      ToastificationContent,
      DashboardSimulacaoProposta,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        acesso: {
          DashboardsCadastro: false,
          DashboardsProposta: false,
          DashboardsFinanceiro: false,
        },
      }
    },
    computed: {
      todosFalsos() {
        return Object.values(this.acesso).every((valor) => valor === false)
      },
    },

    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.DashboardsCadastro = true
        this.acesso.DashboardsProposta = true
        this.acesso.DashboardsFinanceiro = true
      } else {
        this.acesso.DashboardsCadastro = this.userData.GrupoAcesso.DashboardsCadastro
        this.acesso.DashboardsProposta = this.userData.GrupoAcesso.DashboardsProposta
        this.acesso.DashboardsFinanceiro = this.userData.GrupoAcesso.DashboardsFinanceiro
      }
    },
  }
</script>
