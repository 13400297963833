<script>
  import Ripple from 'vue-ripple-directive'
  import { formatarData } from '@/libs/utils'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      formatarData,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        url: '',
        base: '',
      }
    },
    mounted() {
      this.getURL()
    },
    methods: {
      async getURL() {
        const institutoSelecionado = this.userData.institutoSelecionado

        useJwt.get(`integracoes/${institutoSelecionado}`).then((response) => {
          this.url = response.data.dashboardCadastro
        })
      },
    },
  }
</script>

<template>
  <b-card style="overflow-x: auto">
    <iframe title="BI_AgendaBank_V1" id="BI_AgendaBank_V1" :src="url" frameborder="0" allowFullScreen="false"></iframe>
  </b-card>
</template>

<style>
  iframe#BI_AgendaBank_V1 {
    width: 100%;
    height: 1020px;
  }

  iframe#BI_AgendaBank_V1 div.unselecttable {
    display: none !important;
  }
</style>
